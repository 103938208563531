<template>
    <v-dialog v-model="dialog" tile flat max-width="80%" v-if="value">
        <!-- {{value}} -->
        <template v-slot:activator="{ on, attrs }">
            <!-- <v-btn text small block v-bind="attrs" v-on="on">View</v-btn> -->
            <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-avatar size="60" @click="viewsCompanyDetails(value.job.company)">
                    <v-img src="@/assets/images/avatars/profil.svg"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title><strong>{{ value.job.title }}</strong></v-list-item-title>
                    <v-list-item-subtitle v-if="value.job">
                        <span >{{ value.job.company.name }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="flex flex-row">
                        <v-img :src="require('@/assets/images/flags/' + value.job.country.iso + '.svg')"
                            :lazy-src="'@/assets/images/flags/' + value.job.country.iso + '.svg'" aspect-ratio="1"
                            width="1.6em" max-width="1.6em" height="1em"
                            class="text-center subsecondary lighten-2 mr-2">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-skeleton-loader v-bind="attrs" type="image" height="100%" width="100%"
                                        class="mx-auto rounded-pill"></v-skeleton-loader>
                                </v-row>
                            </template>
                        </v-img>
                        <div>{{ value.job.location }}</div>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
        <v-card class="pa-8" max-width="100%" outlined tile>
            <!-- {{value}} -->
            <div class="d-flex flex-row">
                <h2 class="pa-0">
                    <strong>{{ value.job.title }}</strong>
                </h2>
                <v-spacer></v-spacer>
                <v-btn icon class="subsecondary" @click="dialog = false">
                    <v-icon color="secondary">{{ icons.mdiClose }}</v-icon>
                </v-btn>
            </div>
            <div>
                <v-list three-line>
                    <v-list-item two-line class="pa-0">
                        <v-list-item-avatar size="50">
                            <v-img src="@/assets/images/avatars/profil.svg"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <!-- <v-list-item-subtitle v-if="value.user">
                                {{ value.user.firstname }}
                                {{ value.user.lastname }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                {{ value.user.email }} <br />
                                {{ value.user.phonenumber }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle outlined>
                                <span>{{ value.user.country.name }}</span>
                            </v-list-item-subtitle> 
                            <div class="feed-back-job-outline" v-html="value.about"></div>
                            -->
                            <v-list-item-subtitle v-if="value.job">
                                <span>{{ value.job.company.name }}</span>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="d-flex flex-row">
                                <v-img :src="require('@/assets/images/flags/' + value.job.country.iso + '.svg')"
                                    :lazy-src="'@/assets/images/flags/' + value.job.country.iso + '.svg'"
                                    aspect-ratio="1" width="1.6em" max-width="1.6em" height="1em"
                                    class="text-center subsecondary lighten-2 mr-2">
                                    <template v-slot:placeholder>
                                        <v-row class="fill-height ma-0" align="center" justify="center">
                                            <v-skeleton-loader v-bind="attrs" type="image" height="100%" width="100%"
                                                class="mx-auto rounded-pill"></v-skeleton-loader>
                                        </v-row>
                                    </template>
                                </v-img>
                                <div>
                                    {{ value.job.location }}
                                </div>

                                <v-spacer></v-spacer>
                                <div class="error--text">{{ checkJobStatut(value.job.expire_at) }}</div>
                            </v-list-item-subtitle>

                        </v-list-item-content>

                    </v-list-item>
                </v-list>
            </div>
            <v-divider></v-divider>
            <div class="d-flex flex-row my-4">
                <span class="mr-4">
                    <v-icon color="error" class="mr-1">{{ icons.mdiAlertOutline }}</v-icon>
                    Date: {{ value.job.expire_at }}
                </span>
                <v-spacer></v-spacer>

                <!-- <router-link :to="{ name: 'company.views', params: { f_id: this.md5(value.job.company.id), ids: value.job.company.id } }"
                    target="_blank" class="text-decoration-none"> -->
                    <v-btn text class="subsecondary" color="secondary" small @click="viewsCompanyDetails(value.job.company)">
                        {{ value.job.company.name }}
                    </v-btn>
                <!-- </router-link> -->
            </div>
            <v-divider></v-divider>
            <p><b>Job motivation</b></p>
            <div class="px-0" v-html="value.response"></div>
        </v-card>
    </v-dialog>
</template>

<script>
import { mdiClose, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase } from '@mdi/js'
import { VueEditor } from "vue2-editor";
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import result from 'autoprefixer/data/prefixes';
import { isNull } from 'url/util';
var md5 = require("md5")

export default {
    props: {
        value: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            dialog: false
        }
    },
    methods: {
        checkJobStatut(value) {
            var result = ""
            if (value < Date()) {
                result = "closed"
            }

            return result
        },
        
        viewsCompanyDetails(value) {
            this.$router.push({ name: "company.views", params: { f_id: md5(value.id), ids: value.id } })
        }
    },
    setup() {
        const icons = { mdiClose, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase }

        return {
            icons
        }
    },

}
</script>
<style lang="scss" scoped>
@import '@/styles/styles.scss';

.feed-back-job-outline {
    //border-radius: 0.5em;
    padding: 0px 0.3em;
    border-top: solid 1px #ececec;
    border-bottom: solid 1px #ececec;
}
</style>